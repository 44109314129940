/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { Col, Card, Row } from "react-bootstrap";
import { connect } from "react-redux";
import iphoneMoc from "../../../styles/images/landing/iPhone11.png";

const DownloadAppBanner = (props) => {
  const { dictionary } = props.lang;

  return (
    <Col md={6} xl={4}>
      <div className="d-none d-lg-block">
        <Card className="custom-bailo-gradiente">
          <Card.Body>
            <Row>
              <Col xl={4} md={2} className="mt-5">
                <div className="grid">
                  <figure
                    className="effect-ming"
                    style={{
                      background: "transparent",
                    }}
                  >
                    <img src={iphoneMoc} alt="Download" />
                    <figcaption
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "normal",
                      }}
                    >
                      <h2
                        className="text-center"
                        style={{
                          fontFamily: `Montserrat`,
                          textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                        }}
                      >
                        {dictionary.downloadApp.split(" ")[0]}
                        <br />
                        <span> {dictionary.downloadApp.split(" ")[1]}</span>
                      </h2>
                    </figcaption>
                  </figure>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      {/* Segundo Card */}
      <Card className="custom-bailo-gradiente">
        <Card.Body>
          <div
            style={{
              minHeight: "20%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "white",
              padding: "20px",
            }}
          >
            <p
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                fontFamily: `Montserrat`,
              }}
            >
              {dictionary.whatsappContact}
            </p>

            <a
              href="https://chat.whatsapp.com/HPoSl8lt4EL2RkCyQ4up00"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "white",
                fontSize: "1.5em",
              }}
            >
              <i
                className="fa-brands fa-whatsapp"
                style={{ fontSize: "2em", marginRight: "8px" }}
              ></i>
              {dictionary.SendMessage}
            </a>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
  layout: state.layout,
});

export default connect(mapStateToProps)(DownloadAppBanner);
